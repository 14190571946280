/* eslint-disable */
module.exports = (function() {
  var value;

  var _refreshValue = function() {
    value = window
      .getComputedStyle(document.querySelector('body'), ':before')
      .getPropertyValue('content')
      .replace(/\"/g, '')
      .replace(/\'/g, '');
  };

  return function() {
    _refreshValue();

    document.addEventListener('DOMContentLoaded', function() {
      _refreshValue();
    });
    window.addEventListener('resize', function() {
      _refreshValue();
    });

    return value;
  };
})();
