import '../Css/scss/styles.scss';

import $ from 'jquery';
// eslint-disable-next-line no-undef
window[$] = window.jQuery = $;
require('jquery-migrate');
require('jquery-parallax.js');
import AOS from 'aos';

// Custom modules, see ./modules
import {Header} from './modules/header';
import {ScrollToTop} from './modules/scroll-to-top';

window.linkTo_UnCryptMailto = require('./modules/uncryptMailto.js').uncryptMailto;

$(() => {
  new Header();
  new ScrollToTop();

  AOS.init({
    offset: 100,
    easing: 'ease',
    delay: 0,
  });
});
