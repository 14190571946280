module.exports = (function() {
  'use strict';

  var decryptCharcode = function(n, start, end, offset) {
    n = n + offset;
    if (offset > 0 && n > end) {
      n = start + (n - end - 1);
    } else if (offset < 0 && n < start) {
      n = end - (start - n - 1);
    }
    return String.fromCharCode(n);
  };

  var decryptString = function(enc, offset) {
    var dec = '';
    var len = enc.length;
    for (var i = 0; i < len; i++) {
      var n = enc.charCodeAt(i);
      if (n >= 0x2b && n <= 0x3a) {
        dec += decryptCharcode(n, 0x2b, 0x3a, offset); // 0-9 . , - + / :
      } else if (n >= 0x40 && n <= 0x5a) {
        dec += decryptCharcode(n, 0x40, 0x5a, offset); // A-Z @
      } else if (n >= 0x61 && n <= 0x7a) {
        dec += decryptCharcode(n, 0x61, 0x7a, offset); // a-z
      } else {
        dec += enc.charAt(i);
      }
    }
    return dec;
  };

  var uncryptMailto = function(s) {
    location.href = decryptString(s, -1);
  };

  return {
    uncryptMailto: uncryptMailto,
  };
})();
