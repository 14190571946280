/**
 * Header/Navigation
 * @module Navigation
 */

import $ from 'jquery';
let mediaQuery = require('./mq-import.js');

export class Header {

  constructor() {
    this.$body = $('body');
    this.$header = $('#js-page-header');
    this.$navigation = this.$header.find('#js-navigation');
    this.$toggle = this.$header.find('#js-mobile-toggle');
    this.$overlay = $('#js-overlay');
    this.transitionTime = 300;
    this.$hasThirdLevel = document.querySelectorAll('.js-next-level-desktop');
    this.$thirdLevel = document.querySelectorAll('.js-third-level');

    this.shrinkOnScroll();
    this.bindEvents();
  }

  /**
   * Shrink header when scrolling down
   */
  shrinkOnScroll() {
    const _t = this;

    let initalScrollPosition = 0;

    $(window).on('scroll', function() {
      let currentScrollPosition = $(this).scrollTop();

      if (currentScrollPosition > initalScrollPosition) {
        // Downscroll
        if ($(document).scrollTop() > 50) {
          _t.$header.addClass('is-shrinked');
        }
      }
      else {
        // Upscroll
        if ($(document).scrollTop() < 800) {
          _t.$header.removeClass('is-shrinked');
        }
      }
      if ($(document).scrollTop() === 0) {
        _t.$header.removeClass('is-shrinked');
      }
      initalScrollPosition = currentScrollPosition;
    });
  }

  /**
   * Open the mobile menu
   */
  open() {
    const _t = this;

    _t.$toggle.find('.js-hamburger').addClass('is-active');
    _t.$navigation.addClass('is-active');
    _t.$overlay.addClass('is-active');
    _t.$body.addClass('is-scroll-locked');
  }

  /**
   * Close the mobile menu
   */
  close() {
    const _t = this;

    _t.$toggle.find('.js-hamburger').removeClass('is-active');
    _t.$navigation.removeClass('is-active');
    _t.$body.removeClass('is-scroll-locked');
    _t.$overlay.removeClass('is-active').find('is-active').remove('is-active');
  }

  /**
   * Bind all navigation events
   * @private
   */
  bindEvents() {
    const $firstLevel = this.$navigation.find('.js-first-level');
    let mouseenterTimer = false,
      mouseleaveTimer = false;

    // Desktop: Toggle Third Level Navigation
    for (let i = 0; i < this.$hasThirdLevel.length; i++) {
      this.$hasThirdLevel[i].addEventListener('click', ()=> {
        this.$thirdLevel[i].classList.toggle('is-active');
        this.$hasThirdLevel[i].classList.toggle('is-active');
      });
    }

    // Mobile: Toggle to open menu
    this.$toggle.on('click', () => {
      if (!this.$navigation.hasClass('is-active')) {
        this.open();
      }
      else {
        this.close();
      }
    });

    // Mobile: Toggle for level up/down
    this.$navigation.on('click', '.js-next-level', (e) => {
      const $parentList = $(e.currentTarget).parents('ul');

      this.$navigation.scrollTop(0);

      // 2 → 3
      if ($parentList.hasClass('js-second-level')) {
        this.$navigation.find('.js-third-level.is-active').removeClass('is-active');
        $(e.currentTarget).parent('li').find('.js-third-level').addClass('is-active');
        this.$navigation.removeClass('show-second-level').addClass('show-third-level');
      }
      // 1 → 2
      else if ($parentList.hasClass('js-first-level')) {
        this.$navigation.find('.js-second-level.is-active').removeClass('is-active');
        $(e.currentTarget).parent('li').find('.js-second-level').addClass('is-active');
        this.$navigation.removeClass('show-third-level').addClass('show-second-level');
      }
    }).on('click', '.js-back-level', () => {
      // 2 ← 1
      if (this.$navigation.hasClass('show-second-level')) {
        this.$navigation.removeClass('show-second-level');
      }
      // 3 ← 2
      else if (this.$navigation.hasClass('show-third-level')) {
        this.$navigation.removeClass('show-third-level').addClass('show-second-level');
      }
    });


    // Mobile: Close menu before opening a link
    this.$header.on('click', 'a', (e) => {
      if (this.$navigation.hasClass('is-active')) {
        e.preventDefault();
        const url = $(e.currentTarget).attr('href');

        this.close();
        setTimeout(function () {
          window.location = url;
        }, this.transitionTime);
      }
    });

    // Mobile: Close menu on click on overlay
    this.$overlay.on('click', () => {
      this.close();
    });

    $firstLevel.on('mouseenter', '> li', (e) => {
      if (mediaQuery() !== 'xl') { return; }

      const $menuItem = $(e.currentTarget);

      clearTimeout(mouseleaveTimer);

      if ($firstLevel.find('>li.is-hovered').length) {
        $firstLevel.find('>li.is-hovered').removeClass('is-hovered');
        if ($menuItem.find('.js-second-level').length) {
          $menuItem.addClass('is-hovered');
        }
      }
      else {
        mouseenterTimer = setTimeout(() => {
          $firstLevel.find('>li.is-hovered').removeClass('is-hovered');
          if ($menuItem.find('.js-second-level').length) {
            $menuItem.addClass('is-hovered');
          }
        }, 200);
      }
    }).on('mouseleave touchend', '> li', (e) => {
      if (mediaQuery() !== 'xl') { return; }

      const $menuItem = $(e.currentTarget);

      clearTimeout(mouseenterTimer);

      mouseleaveTimer = setTimeout(() => {
        $menuItem.removeClass('is-hovered');
        $('.js-third-level').removeClass('is-active');
        $('.js-next-level-desktop').removeClass('is-active');
      }, 350);
    }).on('touchstart', '> li > a', (e) => {
      if (mediaQuery() !== 'xl') { return; }

      const $menuItem = $(e.currentTarget).parent('li');

      if ($menuItem.find('.js-second-level').length) {
        e.preventDefault();
        $menuItem.trigger('mouseenter');
      }
    });
  }

}
