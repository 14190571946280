/**
 * Display scroll to top indicator and ... scroll to top ;)
 * @module scroll-to-top
 */
import $ from 'jquery';
let mediaQuery = require('./mq-import.js');

export class ScrollToTop {
  constructor() {
    this.$indicator = $('#js-scroll-to-top');

    this.scrollThreshold = 400; // in px from top
    this.scrollDuration = 800; // in ms

    this.bindEvents();
  }

  bindEvents() {
    $(window).on('scroll', e => {
      if (
        mediaQuery() === 'xs' ||
        mediaQuery() === 'sm' ||
        mediaQuery() === 'md'
      ) {
        return;
      }

      if ($(e.currentTarget).scrollTop() > this.scrollThreshold) {
        this.$indicator.fadeIn();
      } else {
        this.$indicator.fadeOut();
      }
    });

    this.$indicator.on('click', e => {
      e.preventDefault();
      $('html, body').animate({scrollTop: 0}, this.scrollDuration);
    });
  }
}
